<template>
	<v-card flat color="transparent" class="mx-auto mt-5" rounded="xl">
		<v-card-title class="my-3">
			<span class="text-h5">{{ $t('settings.deleteAccount') }}</span>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12" md="6" sm="12">
					<v-alert border="top" elevation="8" type="error" color="red darken-3" max-width="500" dark class="mx-auto text-uppercase text-center">
						{{ $t('settings.dangerZone') }}
						<br />
						{{ $t('settings.dangerZoneHelp') }}
					</v-alert>
				</v-col>
				<v-col cols="12" md="6" sm="12">
					<v-form ref="form" v-model="valid">
						<v-text-field
							v-model="password"
							outlined
							type="password"
							:label="$t('auth.password')"
							:rules="rules.password"
							required
							rounded
							prepend-inner-icon="mdi-lock"
							hide-details="auto"
							class="pb-3"
						/>
					</v-form>
				</v-col>
			</v-row>
		</v-card-text>

		<v-card-actions>
			<v-spacer />
			<v-btn x-large rounded :loading="loading" class="px-4" color="primary" @click="callDeleteAccount()">
				{{ $t('settings.delete') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'SettingsDeleteAccount',
	data() {
		return {
			loading: false,
			valid: false,
			password: '',
			rules: {
				password: [(v) => !!v || this.$t('rules.required')],
			},
		}
	},
	computed: {
		...mapGetters({
			currentUser: 'user/currentUser',
		}),
	},
	methods: {
		callDeleteAccount() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.deleteAccount({
				password: this.password,
			})
				.then(({ success }) => {
					if (success) this.$router.push({ name: 'AuthLogout' })
				})
				.then(() => {
					this.loading = false
				})
		},
		...mapActions('settings', ['deleteAccount']),
	},
}
</script>
